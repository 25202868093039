import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./theme";

import { Home } from "./pages/home";
import { About } from "./pages/about";
import { Services } from "./pages/services";

import { createContext } from "@chakra-ui/react-utils";
import { useEffect, useState } from "react";
import { Portfolio } from "./pages/portfolio";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/nosotros",
    element: <About />,
  },
  {
    path: "/servicios",
    element: <Services />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
]);

function App() {
  const [lang, setLang] = useState(window.localStorage.getItem("lang") || "es");

  useEffect(() => {
    window.localStorage.setItem("lang", lang);
  }, [lang]);

  const t = (es, en) => {
    if (lang === "es") return es;
    return en;
  };

  return (
    <ThemeProvider theme={theme}>
      <LangProvider value={{ lang, setLang, t }}>
        <CssBaseline />
        <RouterProvider router={router} />
      </LangProvider>
    </ThemeProvider>
  );
}

export const [LangProvider, useLangContext] = createContext({
  name: "LangProvider",
});

export default App;
