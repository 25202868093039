import { Box, Typography, Button } from "@mui/material";
import { Container } from "../../../../components/Container";
import { useIsDesktop } from "../../../../utils/useIsDesktop";
import { Swiper, SwiperSlide } from "swiper/react";

import { ArrowForward } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { EffectCoverflow, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { useLangContext } from "../../../../App";

import Digidrone from "./assets/digidrone.png";
import M3Estudio from "./assets/m3estudio.png";
import Urbanpisos from "./assets/urbanpisos.png";
import consultoraindustrial from "./assets/consultoraindustrial.png";

import { portfolioData } from "./portfolio";

export const Portfolio = () => {
  const isDesktop = useIsDesktop();
  const ComponentWrapper = isDesktop ? Box : Container;
  const TitleWrapper = isDesktop ? Container : Box;
  const navigate = useNavigate();
  const { t } = useLangContext();

  return (
    <ComponentWrapper sx={{ py: 4 }}>
      <TitleWrapper
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 3,
        }}
      >
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", lineHeight: 1.4 }}
        >
          {t("Portfolio.", "Portfolio.")}
        </Typography>

        <Button onClick={() => navigate("/portfolio")}>
          {t("Ver todos", "See all")}
          <ArrowForward sx={{ ml: 1 }} />
        </Button>
      </TitleWrapper>

      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        slidesPerView={isDesktop ? 3 : 1}
        centeredSlides={true}
        spaceBetween={100}
        loop={true}
        coverflowEffect={{
          rotate: 20,
          stretch: 0,
          depth: isDesktop ? 200 : 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        style={{ paddingTop: isDesktop ? 48 : 0, paddingBottom: 32 }}
      >
        {portfolioData.map((item) => (
          <SwiperSlide>
            {({ isActive }) => <ItemSwipe active={isActive} item={item} />}
          </SwiperSlide>
        ))}
      </Swiper>
    </ComponentWrapper>
  );
};

const ItemSwipe = ({ item, active }) => {
  const { lang, t } = useLangContext();
  return (
    <Box sx={{ width: { xs: "auto", md: 500 } }}>
      <Box
        component="img"
        sx={{
          objectFit: "cover",
          objectPosition: "center",
          height: 332,
          width: "100%",
          borderRadius: 2,
        }}
        src={item.image}
        alt="placeholder"
      />

      {active && (
        <Box p={2}>
          <Typography variant="body2">
            {item.type[lang]} / {item.year}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600, py: 1.5 }}>
            {item.title}
          </Typography>
          <Button
            onClick={() =>
              window.open(item.to, "_blank", "noopener,noreferrer")
            }
            variant="outlined"
            sx={{ mt: { xs: 1, md: 1 } }}
          >
            {t("Ver trabajo", "See work")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
