import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Marquee from "react-fast-marquee";
import SeparateIcon from "@mui/icons-material/AcUnit";
import { WhatsAppButton } from "../../../../components/WhatsAppButton";
import Video from "./bg.mp4";
import Preview from "./preview.png";
import { useLangContext } from "../../../../App";

export const Hero = () => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useLangContext();

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          py: 10,
          pt: 18,
          px: 2,
        }}
      >
        <Box sx={{ textAlign: { xs: "left", md: "center" } }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              textTransform: "uppercase",
              lineHeight: 1.1,
              fontSize: { xs: 54, md: 148 },
            }}
          >
            {t("Creando", "Creating the")}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 300,
              textTransform: "uppercase",
              lineHeight: 1.1,
              fontSize: { xs: 54, md: 148 },
            }}
          >
            {t("futuro", "digital")}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              textTransform: "uppercase",
              lineHeight: 1.1,
              fontSize: { xs: 54, md: 148 },
            }}
          >
            {t("digital", "future")}
          </Typography>

          <WhatsAppButton sx={{ mt: 4 }} />
        </Box>
      </Box>
      <Marquee
        style={{
          borderTop: "1px solid rgba(255, 255, 255, 0.2)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        }}
      >
        <Box
          display="flex"
          sx={{
            py: 5,
            alignItems: "center",
          }}
        >
          <SeparateIcon />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              px: 4,
              fontSize: { xs: "inherit", md: 54 },
            }}
          >
            {t("PÁGINAS WEB", "WEB PAGES")}
          </Typography>
          <SeparateIcon />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 300,
              px: 4,
              fontSize: { xs: "inherit", md: 54 },
            }}
          >
            {t("BRANDING / DISEÑO GRÁFICO", "BRANDING / GRAPHIC DESIGN")}
          </Typography>
          <SeparateIcon />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              px: 4,
              fontSize: { xs: "inherit", md: 54 },
            }}
          >
            {t("SISTEMAS WEB", "WEB APPLICATIONS")}
          </Typography>
          <SeparateIcon />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 300,
              px: 4,
              fontSize: { xs: "inherit", md: 54 },
            }}
          >
            {t("REDACCIÓN DIGITAL", "DIGITAL WRITING")}
          </Typography>
        </Box>
      </Marquee>

      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          background: "rgba(18,18,18,0.85)",
          zIndex: -2,
        }}
      />
      <Box
        component="video"
        autoPlay={isNotMobile}
        poster={Preview}
        loop
        muted
        style={{
          position: "absolute",
          width: { xs: "auto", md: "100%" },
          height: { xs: "100%", md: "auto" },
          left: 0,
          top: 0,
          zIndex: -3,
        }}
      >
        <source src={Video} type="video/mp4" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: 120,
          backgroundImage:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(18, 18, 18, 1))",
          bottom: 0,
          left: 0,
          zIndex: -1,
        }}
      />
    </Box>
  );
};
