import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "../../../../components/Container";
import { useNavigate } from "react-router-dom";
import { useLangContext } from "../../../../App";

export const Services = () => {
  const navigate = useNavigate();
  const { t } = useLangContext();

  const handleNavigate = (route) => {
    window.scrollTo(0, 0);
    navigate(route);
  };
  return (
    <Container
      sx={{
        display: { xs: "block", md: "flex" },
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", lineHeight: 1.4 }}
        >
          {t("Servicios", "Our")} <br /> {t("que ofrecemos", "services")}.
        </Typography>
      </Box>
      <Box sx={{ mt: { xs: 4, md: 0 }, width: { xs: "auto", md: 580 } }}>
        <Accordion sx={{ background: "transparent" }} elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            sx={{
              px: 0,
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: { xs: 18, md: 28 }, fontWeight: 600 }}>
              {t("Desarrollo web personalizado", "Custom web development")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {t(
                "Creación de sitios web a medida, desde páginas de inicio simples hasta sitios web complejos y robustos.",
                "Creation of custom websites, from simple homepages to complex and robust websites."
              )}
            </Box>

            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={() => handleNavigate("/servicios")}
            >
              {t("Más información", "More information")}
            </Button>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ background: "transparent" }} elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            sx={{
              px: 0,
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: { xs: 18, md: 28 }, fontWeight: 600 }}>
              {t("Branding / Diseño gráfico", "Branding / Graphic design")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {t(
                "Te ayudamos a construir una identidad de marca única, potenciando tu presencia y destacándote en el mercado mediante diseños e ideas innovadoras.",
                "We help you build a unique brand identity, enhancing your presence and standing out in the market through innovative designs and ideas."
              )}
            </Box>
            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={() => handleNavigate("/servicios")}
            >
              {t("Más información", "More information")}
            </Button>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ background: "transparent" }} elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            sx={{
              px: 0,
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: { xs: 18, md: 28 }, fontWeight: 600 }}>
              {t("Sistemas web", "Web systems")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {t(
                "Creación de aplicaciones web modernas y funcionales utilizando las últimas tecnologías y mejores prácticas de desarrollo.",
                "Creation of modern and functional web applications using the latest technologies and best development practices."
              )}
            </Box>

            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={() => handleNavigate("/servicios")}
            >
              {t("Más información", "More information")}
            </Button>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ background: "transparent" }} elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            sx={{
              px: 0,
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: { xs: 18, md: 28 }, fontWeight: 600 }}>
              {t("Redacción de contenido digital", "Digital content writing")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {t(
                "Diseño de la experiencia del usuario a través de la redacción de contenido claro, conciso y orientado a la acción para mejorar la usabilidad y la satisfacción del usuario.",
                "User experience design through clear, concise, and action-oriented content writing to enhance usability and user satisfaction."
              )}
            </Box>

            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={() => handleNavigate("/servicios")}
            >
              {t("Más información", "More information")}
            </Button>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};
