import { Box, Container, Typography } from "@mui/material";

import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Helmet } from "../../components/Helmet";

import { Posts } from "./components/Posts";
import { SectionHeader } from "../../components/SectionHeader";

import { useEffect } from "react";
import { useLangContext } from "../../App";

export const Portfolio = () => {
  const { t } = useLangContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Helmet title="Porfolio | Julabs" />

      <Header />

      <SectionHeader
        title={t("Portfolio", "Portfolio")}
        subtitle={t("Nuestros trabajos", "Our jobs")}
      />

      <Box
        component="img"
        src={
          "https://mrwallpaper.com/images/hd/argentina-obelisco-street-monochrome-3bl7pr6neeq69z0f.jpg"
        }
        mt={10}
        sx={{
          objectFit: "cover",
          objectPosition: "center",
          height: { xs: "40vh", md: "400px" },
          width: "100%",
          display: "block",
          margin: "0 auto",
          filter: "brightness(0.5)",
        }}
      />

      <Posts />

      <Footer />
    </Box>
  );
};
