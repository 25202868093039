import {
  Box,
  Typography,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Header } from "../../components/Header";
import { Helmet } from "../../components/Helmet";
import { Footer } from "../../components/Footer";

import Bg from "./assets/bg.jpg";
import { SectionHeader } from "../../components/SectionHeader";
import { Container } from "../../components/Container";

import Video from "../home/components/Hero/bg.mp4";
import Preview from "../home/components/Hero/preview.png";
import { WhatsAppButton } from "../../components/WhatsAppButton";

export const About = () => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Helmet title="Sobre nosotros | Julabs" />
      <Header sx={{ border: 0 }} />
      <SectionHeader title="Nosotros" subtitle="Agencia creativa" image={Bg} />

      <Container sx={{ pt: 12, py: 16 }}>
        <Typography textTransform="uppercase">- SOBRE NOSOTROS</Typography>
        <Typography
          textTransform="uppercase"
          variant="h6"
          fontWeight={600}
          sx={{
            mt: 1,
            fontSize: { xs: "auto", md: 50 },
            lineHeight: { xs: "auto", md: 1.1 },
          }}
        >
          ESTAMOS COMPROMETIDOS A CREAR SOLUCIONES QUE IMPULSEN EL ÉXITO DE TU
          NEGOCIO.
        </Typography>

        <Typography mt={3} variant="body1" lineHeight={1.7}>
          En nuestra agencia, reconocemos la singularidad de cada proyecto y nos
          destacamos por nuestra capacidad para adaptarnos a diversos entornos.
          Hemos colaborado con una amplia gama de clientes, lo que nos ha
          permitido comprender una variedad de necesidades y preferencias. Esta
          experiencia nos impulsa a ofrecer soluciones personalizadas y
          efectivas para cualquier desafío que enfrentemos
        </Typography>

        <WhatsAppButton sx={{ mt: 4 }} variant="outlined" />

        <Grid container spacing={6} mt={0}>
          <Grid item xs={12} md={4}>
            <Item title="Años de experiencia" number="+10" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Item title="Proyectos" number="28" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Item title="Profesionales" number="4" />
          </Grid>
        </Grid>

        <Box position="relative" mt={10} pt={4} pb={14}>
          <Grid
            container
            spacing={0}
            mt={0}
            gap={0}
            columnSpacing={2}
            rowSpacing={8}
          >
            <Grid item xs={6} md={4}>
              <LogoItem img="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Mercadolibre_textlogo.svg/2560px-Mercadolibre_textlogo.svg.png" />
            </Grid>
            <Grid item xs={6} md={4}>
              <LogoItem img="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Telecom_logo_2021.svg/1024px-Telecom_logo_2021.svg.png" />
            </Grid>
            <Grid item xs={6} md={4}>
              <LogoItem img="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Banco_Santander_Logotipo.svg/2560px-Banco_Santander_Logotipo.svg.png" />
            </Grid>
            <Grid item xs={6} md={4}>
              <LogoItem img="https://mentatech.io/wp-content/uploads/2023/07/MENTATECH-LogoCompleto-Negro.svg" />
            </Grid>
            <Grid item xs={6} md={4}>
              <LogoItem img="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Entravision.svg/1280px-Entravision.svg.png" />
            </Grid>
            <Grid item xs={6} md={4}>
              <LogoItem img="https://www.lentesplus.com/media/wysiwyg/landings/pickit/logoPickit.png" />
            </Grid>
          </Grid>
          <Box
            component="video"
            autoPlay={isNotMobile}
            poster={Preview}
            loop
            muted
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -2,
            }}
          >
            <source src={Video} type="video/mp4" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              background: "rgba(18,18,18,0.8)",
              zIndex: -2,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: 120,
              backgroundImage:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(18, 18, 18, 1))",
              bottom: 0,
              left: 0,
              zIndex: -1,
            }}
          />
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

const Item = ({ title, number }) => {
  return (
    <>
      <Typography
        variant="h1"
        fontWeight={700}
        sx={{ WebkitTextStroke: "1px white" }}
        color="transparent"
      >
        {number}
      </Typography>
      <Divider sx={{ pt: 1 }} />
      <Typography sx={{ mt: 3 }} fontWeight={600}>
        {title}
      </Typography>
    </>
  );
};

const LogoItem = ({ img }) => {
  return (
    <Box textAlign="center">
      <Box
        component="img"
        src={img}
        sx={{
          filter: "brightness(0) invert(1)",
          height: { xs: "auto", md: 48 },
          width: { xs: "60%", md: "auto" },
        }}
      />
    </Box>
  );
};
