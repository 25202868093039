import { Box, Typography, IconButton, Drawer, ButtonBase } from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import { useState } from "react";
import { Container } from "../Container";
import { Link } from "react-router-dom";
import Logo from "./logo.png";
import { useLangContext } from "../../App";

export const Header = ({ sx = {} }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { t } = useLangContext();

  return (
    <Box>
      <Box
        component="header"
        sx={{
          py: 1.8,
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          position: "fixed",
          width: "100%",
          backdropFilter: "blur(15px)",
          zIndex: 100000,
          ...sx,
        }}
      >
        <Container
          component="nav"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 0,
          }}
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "white",
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
            onClick={() => window.scrollTo(0, 0)}
          >
            <Box component="img" src={Logo} sx={{ height: 38 }} />
            <Typography component="h1" fontSize={24} fontWeight={300}>
              Julabs
            </Typography>
          </Link>
          <Box display="flex" gap={2}>
            <Box display="flex">
              <LangButton lang="es" />
              <LangButton lang="en" />
            </Box>

            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => setOpenMenu((prev) => !prev)}
            >
              {openMenu ? (
                <Close fontSize="medium" />
              ) : (
                <Menu fontSize="medium" />
              )}
            </IconButton>
          </Box>
        </Container>
      </Box>

      <Drawer
        anchor="top"
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        PaperProps={{
          sx: {
            backdropFilter: "blur(15px)",
            background: "rgba(20, 20, 20, 0.5)",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            px: { xs: 4, md: 6 },
          },
        }}
        onClick={(event) => {
          event.stopPropagation();
          setOpenMenu(false);
        }}
      >
        <Container
          component="ul"
          sx={{
            listStyle: "number",
          }}
        >
          <MenuItem route="/">{t('Inicio', 'Home')}</MenuItem>
          <MenuItem route="/servicios">{t('Servicios', 'Services')}</MenuItem>
          <MenuItem route="/nosotros">{t('Nosotros', 'About us')}</MenuItem>
          <MenuItem route="/portfolio">{t('Porfolio', 'Porfolio')}</MenuItem>
        </Container>
      </Drawer>
    </Box>
  );
};

const MenuItem = ({ children, route }) => {
  return (
    <ButtonBase component={Link} sx={{ display: "block" }} to={route}>
      <Box component="li" sx={{ py: 1, px: 2, textTransform: "uppercase" }}>
        <Typography
          variant="h5"
          sx={{
            fontSize: { xs: "1.5rem", sm: 48 },
          }}
        >
          {children}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

const LangButton = ({ lang }) => {
  const { setLang, lang: currentLang } = useLangContext();

  const changeLanguage = (lang) => {
    setLang(lang);
  };

  const img =
    lang === "es"
      ? "https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-7-2048x1283.jpg"
      : "https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png";
  const alt = lang === "es" ? "bandera argentina" : "bandera de estados unidos";
  return (
    <IconButton
      aria-label="delete"
      size="large"
      onClick={() => changeLanguage(lang)}
      sx={{
        backgroundColor:
          currentLang === lang ? "rgba(255, 255, 255, 0.1)" : "transparent",
      }}
    >
      <Box
        component="img"
        src={img}
        alt={alt}
        sx={{
          height: 24,
          width: 24,
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    </IconButton>
  );
};
