import { Helmet as BaseHelmet } from "react-helmet";

export const Helmet = ({ title }) => {
  return (
    <BaseHelmet>
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />
    </BaseHelmet>
  );
};
