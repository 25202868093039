import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Container } from "../Container";
import { Link } from "react-router-dom";
import Logo from "../Header/logo.png";

export const Footer = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        borderTop: "1px solid rgba(255, 255, 255, 0.2)",
      }}
    >
      <Container
        component="nav"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 0,
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: 12,
          }}
          onClick={() => window.scrollTo(0, 0)}
        >
          <Box component="img" src={Logo} sx={{ height: 38 }} />
          <Typography component="h1" fontSize={24} fontWeight={300}>
            Julabs
          </Typography>
        </Link>

        <Typography>© 2024 Julabs agency</Typography>
      </Container>
    </Box>
  );
};
