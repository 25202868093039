import { Button } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useLangContext } from "../App";

export const WhatsAppButton = ({ sx = {}, variant = "contained" }) => {
  const { t } = useLangContext();

  return (
    <Button
      variant={variant}
      onClick={() => window.open("https://wa.me/5492804709753")}
      sx={{
        ...sx,
      }}
    >
      <WhatsAppIcon fontSize="medium" sx={{ mr: 1 }} />
      {t("Habla con nosotros", "Chat with us")}
    </Button>
  );
};
