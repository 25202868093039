import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "Work Sans, sans-serif",
    body1: {
      fontSize: 17
    }
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 24,
          fontSize: 16
        },
      },
    },
  },
});
