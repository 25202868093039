import { Box, Typography, Button, Grid } from "@mui/material";
import { Container } from "../../../../components/Container";

import { useNavigate } from "react-router-dom";

import { portfolioData } from "../../../home/components/Portfolio/portfolio";

import { useLangContext } from "../../../../App";

export const Posts = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Grid container spacing={4}>
        {portfolioData.map((item) => (
          <ItemSwipe item={item} to={item.to} />
        ))}
      </Grid>
    </Container>
  );
};

const ItemSwipe = ({ item }) => {
  const { lang, t } = useLangContext();

  return (
    <Grid item sx={{ width: { xs: "auto", md: 500 } }} xs={12} md={6}>
      <Box sx={{ width: { xs: "auto", md: 500 } }}>
        <Box
          component="img"
          sx={{
            objectFit: "cover",
            objectPosition: "center",
            height: 332,
            width: "100%",
            borderRadius: 2,
          }}
          src={item.image}
          alt="placeholder"
        />

        <Box p={2}>
          <Typography variant="body2">
            {item.type[lang]} / {item.year}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600, py: 1.5 }}>
            {item.title}
          </Typography>
          <Button
            onClick={() =>
              window.open(item.to, "_blank", "noopener,noreferrer")
            }
            variant="outlined"
            sx={{ mt: { xs: 1, md: 1 } }}
          >
            {t("Ver trabajo", "See work")}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};
