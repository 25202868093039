import { Box, Typography } from "@mui/material";
import { Container } from "../Container";

export const SectionHeader = ({ title, subtitle, image }) => {
  return (
    <Box pt={14}>
      <Container>
        <Typography variant="h5" sx={{ textTransform: "uppercase" }}>
          {subtitle}
        </Typography>
        <Typography
          variant="h3"
          sx={{ textTransform: "uppercase", fontSize: { xs: "auto", md: 100 } }}
          fontWeight={600}
        >
          {title}
        </Typography>
      </Container>
      {image && (
        <Box
          sx={{
            overflow: "hidden",
            height: { xs: 400, md: 684 },
            objectFit: "cover",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            src={image}
            component="img"
            sx={{
              width: "100%",
              height: { xs: 400, md: 684 },
              objectFit: "cover",
            }}
          />
        </Box>
      )}
    </Box>
  );
};
