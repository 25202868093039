import { Box, Typography } from "@mui/material";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Helmet } from "../../components/Helmet";

import { SectionHeader } from "../../components/SectionHeader";
import { Container } from "../../components/Container";

import { WhatsAppButton } from "../../components/WhatsAppButton";

import { useLangContext } from "../../App";

import Bg from "./assets/bg.jpg";

export const Services = () => {
  const { t } = useLangContext();
  return (
    <Box>
      <Helmet title="Servicios | Julabs" />
      <Header sx={{ border: 0 }} />
      <SectionHeader
        title={t("Servicios", "Services")}
        subtitle={t("Agencia creativa", "Creative agency")}
      />

      <Box
        component="img"
        src={Bg}
        mt={10}
        sx={{
          height: { xs: 400, md: 600 },
          width: "100%",
          mt: 0,
          objectFit: "cover",
          zIndex: -1,
          position: "relative",
        }}
      />

      <Container
        sx={{
          background: "#202020",
          mt: { xs: -32, md: -48 },
          p: 0,
          zIndex: 1,
          width: { xs: "90%", md: "100%" },
        }}
      >
        <Box py={{ xs: 4, md: 8 }} px={{ xs: 4, md: 6 }}>
          <Typography textTransform="uppercase">
            - {t("Nuestros servicios", "Our services")} -
          </Typography>
          <Typography
            textTransform="uppercase"
            variant="h6"
            fontWeight={600}
            sx={{
              mt: 1,
              fontSize: { xs: "auto", md: 50 },
              lineHeight: { xs: "auto", md: 1.1 },
            }}
          >
            {t(
              "ESTAMOS COMPROMETIDOS A CREAR SOLUCIONES QUE IMPULSEN EL ÉXITO DE TU NEGOCIO.",
              "We are committed to creating solutions that drive your business success."
            )}
          </Typography>

          <Typography mt={3} variant="body1" lineHeight={1.7}>
            {t(
              "Nos dedicamos a ofrecer soluciones web integrales que van más allá de simples diseños. Nos destacamos por nuestra capacidad para crear plataformas digitales que son tanto visualmente atractivas como altamente funcionales, proporcionando a nuestros clientes herramientas efectivas para alcanzar sus metas en línea.",
              "We are dedicated to offering comprehensive web solutions that go beyond mere designs. We excel in creating digital platforms that are both visually appealing and highly functional, providing our clients with effective tools to achieve their online goals."
            )}
          </Typography>
        </Box>
      </Container>

      <Container sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <ServiceItem
          title={t("Desarrollo web", "Web development")}
          text={t(
            "En nuestra agencia, reconocemos la singularidad de cada proyecto y nos destacamos por nuestra capacidad para adaptarnos a diversos entornos. Hemos colaborado con una amplia gama de clientes, lo que nos ha permitido comprender una variedad de necesidades y preferencias. Esta experiencia nos impulsa a ofrecer soluciones personalizadas y efectivas para cualquier desafío que enfrentemos",
            "At our agency, we recognize the uniqueness of each project and take pride in our ability to adapt to diverse environments. We have collaborated with a wide range of clients, which has given us insight into various needs and preferences. This experience drives us to deliver personalized and effective solutions for any challenge we encounter."
          )}
          img="https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <ServiceItem
          title={t("Branding / Diseño gráfico", "Branding / Graphic design")}
          text={t(
            "Te ayudamos a construir una identidad de marca única, potenciando tu presencia y destacándote en el mercado mediante diseños e ideas innovadoras.",
            "We help you build a unique brand identity, enhancing your presence and standing out in the market through innovative designs and ideas."
          )}
          img="https://www.marketingdonut.co.uk/sites/default/files/branding_overview_371705137.jpg"
          even
        />
        <ServiceItem
          title={t("Sistemas web", "Web systems")}
          text={t(
            "Creación de aplicaciones web modernas y funcionales utilizando las últimas tecnologías y mejores prácticas de desarrollo. Nuestro equipo de desarrollo está comprometido con la excelencia y se mantiene al tanto de las últimas tendencias y avances tecnológicos. Desde aplicaciones web simples hasta plataformas complejas, estamos preparados para convertir tus ideas en aplicaciones digitales que destaquen en el competitivo mundo en línea.",
            "Creation of modern and functional web applications using the latest technologies and best development practices. Our development team is committed to excellence and stays up-to-date with the latest trends and technological advancements. From simple web applications to complex platforms, we are ready to turn your ideas into digital applications that stand out in the competitive online world."
          )}
          img="https://images.pexels.com/photos/4549414/pexels-photo-4549414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <ServiceItem
          title={t("Redacción de contenido digital", "Digital content writing")}
          text={t(
            "Diseño de la experiencia del usuario a través de la redacción de contenido claro, conciso y orientado a la acción para mejorar la usabilidad y la satisfacción del usuario. Nos especializamos en diseñar experiencias digitales que priorizan la satisfacción del usuario. Con un enfoque centrado en la redacción de contenido claro, conciso y orientado a la acción, mejoramos la usabilidad y guiamos a los usuarios de manera efectiva a través de tu sitio web.",
            "User experience design through clear, concise, and action-oriented content writing to enhance usability and user satisfaction. We specialize in creating digital experiences that prioritize user satisfaction. With a focus on crafting content that is clear, concise, and action-oriented, we improve usability and effectively guide users through your website."
          )}
          img="https://images.pexels.com/photos/8297479/pexels-photo-8297479.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          even
        />
        <Box pb={10} />
      </Container>

      <Footer />
    </Box>
  );
};

export const ServiceItem = ({ title, text, img, even = false }) => {
  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: { xs: "row", md: even ? "row-reverse" : "row" },
      }}
      display={{ xs: "block", md: "flex" }}
    >
      <Box
        component="img"
        src={img}
        sx={{
          height: { xs: "auto", md: 500 },
          width: { xs: "100%", md: 380 },
          objectFit: "cover",
        }}
      />
      <Box p={5}>
        <Typography variant="h4" textTransform="uppercase" fontWeight={600}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ pt: 3 }}>
          {text}
        </Typography>
        <WhatsAppButton sx={{ mt: 3 }} />
      </Box>
    </Box>
  );
};
