import { Box } from "@mui/material";
import { Hero } from "./components/Hero";
import { Services } from "./components/Services";
import { Company } from "./components/Company";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Portfolio } from "./components/Portfolio";

export const Home = () => {
  return (
    <Box>
      <Header />
      <Hero />
      <Company />
      <Services />
      <Portfolio />
      <Footer />
    </Box>
  );
};
