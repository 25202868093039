import Digidrone from "./assets/digidrone.png";
import M3Estudio from "./assets/m3estudio.png";
import Urbanpisos from "./assets/urbanpisos.png";
import consultoraindustrial from "./assets/consultoraindustrial.png";
import utv from "./assets/utv.png";

export const portfolioData = [
  {
    type: {
      es: "PÁGINA WEB",
      en: "WEB PAGE",
    },
    year: 2024,
    title: "UTV Argentina",
    to: "https://www.utvargentina.com/",
    image: utv,
  },
  {
    type: {
      es: "PÁGINA WEB",
      en: "WEB PAGE",
    },
    year: 2023,
    title: "DIGIDRONE",
    to: "https://digidrone.com.ar/",
    image: Digidrone,
  },
  {
    type: {
      es: "PÁGINA WEB",
      en: "WEB PAGE",
    },
    year: 2021,
    title: "M3 Estudio",
    to: "https://m3estudio.com.ar/",
    image: M3Estudio,
  },
  {
    type: {
      es: "PÁGINA WEB",
      en: "WEB PAGE",
    },
    year: 2020,
    title: "Urban Pisos",
    to: "https://urbanpisos.com.ar/",
    image: Urbanpisos,
  },
  {
    year: 2020,
    type: {
      en: "WEB PAGE | WEB APPLICATION",
      es: "PÁGINA WEB | APLICACIÓN WEB",
    },
    title: "Consultora industrial",
    to: "https://consultoraindustrial.com.ar/",
    image: consultoraindustrial,
  },
];
