import { Box, Typography } from "@mui/material";
import { Container } from "../../../../components/Container";
import { WhatsAppButton } from "../../../../components/WhatsAppButton";
import { useLangContext } from "../../../../App";

export const Company = () => {
  const { t } = useLangContext();
  return (
    <Container
      sx={{
        display: { xs: "block", md: "flex" },
        justifyContent: "space-between",
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", lineHeight: 1.4 }}
        >
          {t("Sobre", "About")} <br /> {t("nosotros", "us")}.
        </Typography>
      </Box>
      <Box sx={{ mt: { xs: 4, md: 0 }, width: { xs: "auto", md: 580 } }}>
        <Typography variant="h5" fontWeight={600}>
          {t(
            "ESTAMOS COMPROMETIDOS A CREAR SOLUCIONES QUE IMPULSEN EL ÉXITO DE TU NEGOCIO.",
            "WE ARE COMMITTED TO CREATING SOLUTIONS THAT DRIVE YOUR BUSINESS SUCCESS."
          )}
        </Typography>
        <Typography mt={3} variant="body1" lineHeight={1.7}>
          {t(
            "En nuestra agencia, reconocemos la singularidad de cada proyecto y nos destacamos por nuestra capacidad para adaptarnos a diversos entornos. Hemos colaborado con una amplia gama de clientes, lo que nos ha permitido comprender una variedad de necesidades y preferencias. Esta experiencia nos impulsa a ofrecer soluciones personalizadas y efectivas para cualquier desafío que enfrentemos",
            "In our agency, we recognize the uniqueness of each project and excel in our ability to adapt to diverse environments. We have collaborated with a wide range of clients, which has allowed us to understand a variety of needs and preferences. This experience drives us to offer personalized and effective solutions for any challenge we face"
          )}
        </Typography>

        <WhatsAppButton variant="outlined" sx={{ mt: 4 }} />
      </Box>
    </Container>
  );
};
